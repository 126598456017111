import './category.css';

export default function CategoryCard (props) {
    return (
        <div className="categoryCard">
            <div className="cardTitleBox">
                <h3>{props.label}</h3>
            </div>
            <div className="cardContents">
                {props.children}
            </div>
        </div>
    );
}
