import './App.css';

import profilePicImg from './res/profile.jpeg';
import HeaderFrame from './elements/header.jsx';
import ResumeBody from './elements/body.jsx';

import Category from './elements/category.jsx';
// eslint-disable-next-line
import Row from './elements/row.jsx';

import Timeframe from './elements/timeframe.jsx';
// eslint-disable-next-line
import Location from './elements/location.jsx';
import Link from './elements/link.jsx';

function App() {
    const profilePic = (
        <img className="profilePic"
             src={profilePicImg}
             alt="Lucas del Pino" />
    );
    return (
        <div className="App">
            <HeaderFrame name="Lucas del Pino"
                         title="Programmer"
                         profilePic={profilePic} />
            <ResumeBody>
                <Category label="My links">
                    <Link href="mailto:delpino.lucas@gmail.com">
                        Email
                    </Link>
                    <Link href="https://gitlab.com/Delpi">
                        Gitlab
                    </Link>
                    <Link href="https://linkedin.com/in/l-del-pino">
                        Linkedin
                    </Link>
                </Category>

                <Category label="Work history">
                    <div>
                        <h4>Tipie</h4>
                        <p>
                            <em>Full stack developer.</em>
                        </p>
                        <Row>
                            <Timeframe start="Jan 2022" />
                            <Link href="https://tipieapp.com">
                                Company site
                            </Link>
                        </Row>
                    </div>
                </Category>

                <Category label="Current projects">
                    <div>
                        <h4>Trash Dweller Productions</h4>
                        <p>
                            <em>As a co-founder and developer.</em>
                            <br />
                            An indie studio focused on games and other media.
                        </p>
                        <Row>
                            <Timeframe start="2021" />
                            <Link href="https://trashdweller.com">
                                TDP main site
                            </Link>
                        </Row>
                    </div>
                </Category>

                <Category label="Past projects">
                    <div>
                        <h4>Extensible Résumé Online</h4>
                        <p>
                            <em>(React, Sass)</em> A single page web app for generating an online résumé based on a simple and extensible set of JSON templates
                        </p>
                        <Link href="https://gitlab.com/Delpi/extensible-resume-online">
                            Repo
                        </Link>
                    </div>
                    <br />
                    <div>
                        <h4>Core messaging mechanics</h4>
                        <p>
                            <em>(C++, Raylib)</em> A prototype for a set of core mechanics for a defunct game project. It simulates a messaging app with theming and scrolling, intended to have been the primary means of interaction between the player and the story.
                        </p>
                        <Link href="https://gitlab.com/Delpi/lukari-prototype">
                            Repo
                        </Link>
                    </div>
                    <br />
                    <div>
                        <h4>Obsidio Mapgen</h4>
                        <p>
                            <em>(Java)</em> A procedural random map generator for a third-party game. It iteratively adjusts the chances of entity recurrence to create natural-looking scenarios.
                        </p>
                        <Link href="https://gitlab.com/Delpi/obsidio-mapgen">
                            Repo
                        </Link>
                    </div>
                    <br />
                    <div>
                        <h4>Tic tac toe</h4>
                        <p>
                            <em>(Lisp)</em> A rewrite of a beginner's project: a simple brute force tic-tac-toe game.
                        </p>
                        <Link href="https://gitlab.com/Delpi/tic-tac-toe">
                            Repo
                        </Link>
                    </div>
                </Category>

                <Category label="Skillset">
                    <div>
                        <h4>Programming languages</h4>
                        <ul>
                            <li>
                                Javascript (Node, Express, React, React Native),
                            </li>
                            <li>
                                C/C++,
                            </li>
                            <li>
                                HTML, CSS (Sass),
                            </li>
                            <li>
                                Java,
                            </li>
                            <li>
                                Common Lisp
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h4>Databases</h4>
                        <ul>
                            <li>
                                SQL (SQLite, PostgreSQL, MySQL),
                            </li>
                            <li>
                                MongoDB (Mongoose), Firestore
                            </li>
                        </ul>
                    </div>
                </Category>
            </ResumeBody>
        </div>
    );
}

export default App;
