import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import './link.css';

export default function Link (props) {
    return (
        <a className="largeLink"
           href={props.href}>
            <FontAwesomeIcon icon={faLink} />
            {" "}
            {props.children}
            <br />
        </a>
    );
}
