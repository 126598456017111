import './header.css';

export default function HeaderFrame (props) {
    return (
        <header>
            <div className="headerTopBar">
                <div className="headerbox">
                    {props.profilePic}
                    <h1>{props.name}</h1>
                </div>
            </div>
            <div className="headerBottomBar">
                <div className="headerbox">
                    <span />
                    <h2>{props.title}</h2>
                </div>
            </div>
        </header>
    );
}
