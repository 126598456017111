import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import './horizontalBanner.css';

function timeString (timeObj) {
    let timeString = "";

    const pushToTime = (prev, time) => {
        if (time) {
            timeString += (prev + time);
        }
    }

    pushToTime(" ", timeObj.start);
    pushToTime(" - ", timeObj.end);

    return timeString;
}

export default function Timeframe (props) {
    const time = timeString({
        start: props.start,
        end: props.end
    });

    return (
        <h5>
            <FontAwesomeIcon icon={faCalendarAlt} />
            {" " + time}
        </h5>
    );
}
