import './body.css';

export default function ResumeBody (props) {
    return (
        <div className="resumeFrame">
            <div className="resumeBody">
                {props.children}
            </div>
        </div>
    );
}
